import { Component } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-doctors-management',
  standalone: true,
  imports: [RouterLink, RouterOutlet, RouterLinkActive, TranslateModule],
  templateUrl: './doctors-management.component.html',
  styleUrl: './doctors-management.component.scss',
})
export class DoctorsManagementComponent {}
